/* Dark Theme Overrides */

.dark-card {
  background-color: #2a2a2a; /* Dark background color for cards */
  border: 1px solid #444; /* Slightly lighter border for contrast */
}

.modal-fullwidth .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.modal-body {
  background-color: #1a1a1a; /* Dark background color for the modal */
}

.modal-image {
  width: 100%; /* Ensuring the modal image takes full width */
}

.btn-secondary {
  background-color: #444; /* Dark background color for the button */
  border: none; /* Removing border for a cleaner look */
}

.btn-secondary:hover {
  background-color: #666; /* Slightly lighter on hover */
}

.clickable-image {
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
}

/* Custom Styles */
.Gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.GalleryItem {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.GalleryItem img {
  width: 100%;
  height: auto;
  display: block;
}

.magnifying-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #f5f5f5; /* Icon color */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px;
  border-radius: 50%;
}

/* Remove white bottom section */
.dark-card .card-body {
  display: none;
}
