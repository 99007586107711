:root {
  --bs-body-bg: #272c30 !important;
}

.LandingPage {
  background-color: #000000; /* Black */
  
}

.section-padding
{
  max-width: 1200px;
  margin: auto;
}
.col{
  padding: 20px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

:root {
  --bs-modal-margin: 1rem; /* Replace with your desired value */
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.App-header {
  background-color: #272c30; /* Dark blue-grey */
  background-image: url(../public/img/github_social_image_no_logo.jpg);
  background-blend-mode: overlay; /* Blends the image with the background color */
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: #ffffff; /* White text color */
  background-attachment:fixed;
}

.Intro,
.CarouselSection,.Integrations{
  text-align: center;
  background-color: #333c46; /* Dark blue-grey */
  color: #bec5cb ; /* White text color */
}

.Features,
.WhyUse,
.CTA  {
  text-align: center;
  background-color: #272c30; /* Dark blue-grey */
  color: #bec5cb; /* White text color */
  padding-bottom: 50px;
}



.Footer {
  text-align: center;
  background-color: #000000; /* Black */
  color: #bec5cb; /* White text color */
  padding: 2em;
  margin-top: 2em;
}

.App-link {
  color: #61dafb;
  background-color: #18181a; /* Dark blue-grey */
  margin: 5px;
}

/* .slice {
  background-color: #333c46;
} */

.col .card-body {
  background-color: #333c46; 
  color: #ecedee;
  --bs-card-border-color: none !important;
  border-radius: 5px;
}


.CTA h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.CTA p {
  font-size: 16px;
  margin-bottom: 30px;
}

.App-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4a90e2; /* Customize button background color */
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.App-link:hover {
  background-color: #0056b3; /* Change button background color on hover */
}

.icon {
  margin-left: 10px;
}

.Footer a {
  margin: 0 10px;
  color: #ffffff; /* Adjust icon color */
  font-size: 24px;
  transition: color 0.3s ease;
}

.Footer a:hover {
  color: #4a90e2; /* Change icon color on hover */
}

.slice .card-text
{
 
  margin-top: 15%;
  justify-content: center; /* Horizontal centering */
  align-items: center;    /* Vertical centering */
  
}

.modal-content{
  width:90%;
  /* max-width: 90%; */
}
.modal-body{
  width:100%;
  max-width: 100%;
}

.row
{
  height: inherit;
}

.slice .card-img
{
  cursor: pointer;
} 

.pointer
{
  cursor: pointer;
}

.Integrations h4
{
  color: #bec5cb !important;
}

.Integrations li
{
  color: #bec5cb !important;
}

/* Header */
.Header
{
  display: block;
  position: fixed;
}

.shield
{
  padding: 3px;
}

/* helpers */
.height-300
{
  height: 300px;
}

.height-250
{
  height: 250px;
}

.Features .card-body
{
  padding: 30px;
  /* margin-right: 25px; */
}

.card-row
{
  padding-top:10px;
}

/* slices */
.background-icon
{
  width: 100px;
  height: 100px;
  opacity: 0.1;
  float: right;
  display: block;
  
}

.magnifying-icon-slice
{
  /* position: absolute; */
  top: 10px;
  right: 10px;
  color: #f5f5f5; /* Icon color */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px;
  border-radius: 50%;
  float: right;
  display: block;
}

.pitch
{
  font-size:larger;
  padding: 50px;
}